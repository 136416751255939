<template>
  <fragment>
    <div
      v-for="(label, value) in getOptions(question.options)"
      :key="value"
      :class="`${question.answerColClass || 'col'} field`"
    >
      <div v-if="question.nextOnClick">
        <button
          :data-qa="questionName + label"
          type="button"
          :class="['btn btn-primary', question.fieldClass, { selected: value === response }]"
          @click="setValueAndNext(path, responsePath, value)"
        >
          <i v-if="question.fieldClass && question.fieldClass.includes('icon')" :class="value" />
          <span v-html="label"></span>
        </button>
      </div>
      <div v-if="!question.nextOnClick">
        <button
          :data-qa="questionName + label"
          type="button"
          :class="['btn btn-primary', question.fieldClass, { selected: value === response }]"
          @click="setValue({ path, responsePath, value })"
        >
          <i v-if="question.fieldClass && question.fieldClass.includes('icon')" :class="value" />
          <span v-html="label"></span>
        </button>
      </div>
    </div>
  </fragment>
</template>
<script>
import { mapState, mapActions } from "vuex";

import { forEach } from "lodash";

import router from "../../router";

import { getOptions, getProp, isRulesVisible } from "../../utils/SurveyUtils";

export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    responsePath: {
      type: String,
      required: false,
      default: ""
    },
    questionName: {
      type: String,
      required: false,
      default: ""
    },
  },
  computed: mapState({
    response() {
      return getProp(this.question, this.responsePath, "response");
    },
    sectionList(state) {
      return state.survey.sections;
    },
    section(state) {
      console.log("value of state : ", state);
      return state.survey.sections[this.$route.params.section];
    },
    isBtNext(state) {
      return state.survey.sections[this.$route.params.section].isBtNextClicked;
    },
    visibilityRulesBtNext(state) {
      return state.survey.sections[this.$route.params.section].visibilityRulesBtNext;
    },
    nextSection(state) {
      // si les visibility rules de la section ne sont pas respectées, on regarde la section d'après ...
      let section = null;
      forEach(this.section.next, function (nextSection, name) {
        if (isRulesVisible(nextSection.visibilityRules, state)) {
          section = name;
        }
      });
      return section;
    },
  }),
  methods: {
    getOptions,
    ...mapActions(["setValue"]),
    setValueAndNext(path, responsePath, value) {
      this.setValue({ path, responsePath, value }).then(() => {
        router.push({
          name: "Questionnaire",
          params: {
            section: this.nextSection,
            declarationId: this.declarationId,
          },
          query: this.$route.query,
        });
      });
    },
  },
};
</script>
