<template>
  <!-- <aside id="sidebar" :class="toogleNavbar ? 'menu-opened' : ''">
    <div id="sidebar-nav" class="animated">
      <img
        src="@/assets/images/logo-groupama-loire-bretagne.svg"
        id="logo"
        alt="Logo Groupama Loire Bretagne"
  />
  <nav class="steps">
    <div v-for="(section, key) in header" :key="key" class="step" :class="section.route">
      <router-link
        :disabled="
          isSurveyCompleted ||
            $route.name === 'completer' ||
            $route.name === 'dejarepondu' ||
            $route.name === 'noclient'
        "
        tag="button"
        :to="{
          name: 'Questionnaire',
          params: { section: section.route, button: 'header' },
          query: $route.query
        }"
        :class="[{ 'router-link-achieve': sections(section.route) }]"
      >
        <div class="icon-block">
          <i :class="section.icon" />
          <span>{{ section.title }}</span>
        </div>
      </router-link>
      <span class="line" />
    </div>
  </nav>
  </div>
    <div id="sidebar-mask" @click="toggleNavBar" class="animated"></div>
  </aside>-->
</template>
<script>
import { mapState, mapActions } from "vuex";
import { getProp } from "../../utils/SurveyUtils";

export default {
  computed: mapState({
    title: state => state.survey.title,
    toogleNavbar: state => state.toogleNavbar,
    icon: state => state.survey.icon,
    isSurveyCompleted: state => state.isSurveyCompleted,
    header: state => state.survey.sections.globals.header,
  }),
  methods: {
    ...mapActions(["toggleNavBar"]),
    sections(section) {
      return getProp(this.$store.state.survey.sections, section, "isValidated");
    },
  },
};
</script>
<style lang="scss" scoped></style>
