<template>
  <div id="layout">
    <v-header />
    <section class="wrapper">
      <app-header v-if="showAppHeader" />
      <section class="content">
        <div class="banner">
          <img class="logo" src="../../assets/images/logo-prosecure.png" />
          <div class="banner-title">Vos appareils de levage vérifiés, pour travailler en toute sécurité</div>
          <div class="form-space"></div>
        </div>
        <router-view v-slot="{ Component }">
          <Transition name="fade" mode="out-in">
            <keep-alive v-if="$route.meta && $route.meta.keepAlive !== false">
              <component :is="Component" />
            </keep-alive>
            <component :is="Component" v-else />
          </Transition>
        </router-view>
      </section>
      <app-footer v-if="showAppFooter" />
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";

import VHeader from "./Header";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";

export default {
  components: {
    VHeader,
    AppHeader,
    AppFooter
  },

  computed: mapState({
    showAppHeader: state => state.showAppHeader,
    showAppFooter: state => state.showAppFooter
    // section(state) {
    //   return state.survey.sections[this.$route.params.section];
    // },
  })
};
</script>
