<template>
  <div :class="`${question.answerColClass || 'col'} field`">
    <div v-if="question.bloc" :class="question.classBloc" v-html="question.bloc"></div>
  </div>
</template>
<script>
export default {
  props: ["question", "path", "responsePath"],
  computed: {},
};
</script>
