<template>
  <div :class="`${question.questionClass || 'col'} field`">
    <div class="checkbox-wrapper">
      <input
        :checked="response"
        @click="onClick()"
        type="checkbox"
        :id="question.serverPath"
      />
      <label :for="question.serverPath"></label>
    </div>
    <label v-if="question.labelInput" :for="question.serverPath"
      >{{ question.labelInput }} </label
    >
    <label v-if="question.labelInputHtml" :for="question.serverPath">
      <component v-if="question.labelInputHtml" :is="renderHtml"></component>
    </label>
  </div>
</template>

<script>
import { getOptions, getProp, getPath } from "../../utils/SurveyUtils";

export default {
  props: ["question", "path", "responsePath"],
  computed: {
    response() {
      return getProp(this.question, this.responsePath, "response");
    }
  },
  methods: {
    getOptions,
    getPath,

    onClick() {
      return this.$store.dispatch("setValue", {
        path: this.path,
        responsePath: this.responsePath,
        value: !this.response || false
      });
    }
  }
};
</script>
