<template>
  <div :class="`${question.answerColClass || 'col'} field`">
    <VueDatePicker
      :name="question.labelInput"
      :placeholder="question.placeholder"
      :class="[filled, question.fieldClass]"
      :value="response"
      @change="e => setValueDate({ path, responsePath, value: e })"
      @input="e => checkError({ path, responsePath, value: e })"
      lang="fr"
      format="dd/MM/yyyy"
      :not-after="new Date()"
      :not-before="new Date(1900, 1, 1)"
    ></VueDatePicker>
    <label v-if="question.labelInput">
      {{ question.labelInput }}
      <!-- Info bulle -->
      <!-- <tooltip-element :question="question" /> -->
    </label>
  </div>
</template>
<script>
import { mapActions } from "vuex";

import VueDatePicker from "@vuepic/vue-datepicker";
import { getProp } from "../../utils/SurveyUtils";

export default {
  props: ["question", "path", "responsePath"],
  computed: {
    response() {
      return getProp(this.question, this.responsePath, "responseFull");
    },
    filled() {
      return this.response && this.response.length > 0 && "filled";
    },
  },
  methods: {
    ...mapActions(["setValueDate", "checkError"]),
  },
  components: {
    VueDatePicker,
  },
};
</script>
