 <template>
   <main class="container">
    <div class="formulaire">
      <div class="container text-center">
        <div class="confirmation-block">
          <i class="icon-confirmation check" />
          <div class="confirm-message">
            <h1>Confirmation</h1>
            <p>
              Merci pour votre demande. Un conseiller Prosecure vous rappellera sous 48H.
            </p>
          </div>
        </div>
        <footer class="confirmation-footer">
        </footer>
      </div>
    </div>
   </main>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
</style>