import { createStore } from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import state from "./state";

const store = createStore({
  state: {
    devisCaisse: state.devisCaisse,
    survey: state.survey,
    surveyWithClientCode: state.surveyWithClientCode,
    isSurveyCompleted: state.isSurveyCompleted,
  },
  mutations,
  actions,
  modules: {},
  strict: !__PROD__,
});

export default store;
