import { createApp } from "vue";

import "./assets/styles/app.scss";

// Import global componenents
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import QuestionLayout from "./components/QuestionLayout";
import TooltipElement from "./components/fields/TooltipElement";

import router from "./router";
import MainLayout from "./components/layouts/MainLayout";
import store from "./store";

const app = createApp({
  setup() {
    return {};
  },
  template: "<main-layout/>",
  components: {
    MainLayout,
  },
  created() {
    const currentSession = sessionStorage.getItem("contact-prosecure");
    let current = null;
    if (currentSession !== "[object Object]") {
      current = JSON.parse(currentSession);
    }
    // Initial sessionstorage
    if (!current) {
      sessionStorage.setItem("contact-prosecure", current);
      // console.log("init tracking");
      // tracking("init");
    }
  },
  watch: {
    "$store.state.isSurveyCompleted": function(isSurveyCompleted) {
      if (isSurveyCompleted === true) {
        router.push({ name: "Complet" });
      }
    },
  },
});
app.use(router);
app.use(store);
// Initiate other plugins here

app.mount("#root");

// QuestionLayout must be global to be call by their children...
// (Why can't use module import in the children, don't know....)
app.component("question-layout", QuestionLayout);
app.component("tooltip-element", TooltipElement);
app.component("VueDatePicker", VueDatePicker);

if (__DEV__) {
  // Remove productionTip
  app.config.productionTip = false;
}

export default app;
