import axios from "axios";
import { get, forEach, keys, split } from "lodash";
import { set as setFP } from "lodash/fp";
import { getPath, getProp, execSurveyDeep } from "../utils/SurveyUtils";
import { getResponseFromSurvey } from "../utils/ServerParser";

export const CHECK_ERROR = "CHECK_ERROR";
export const CHECK_ERROR_FP = "CHECK_ERROR_FP";
export const CHECK_COMPLETED = "CHECK_COMPLETED";
export const SET_VALUE = "SET_VALUE";
export const SET_VALUE_GLOBAL = "SET_VALUE_GLOBAL";
export const SET_VALIDATE_SECTION = "SET_VALIDATE_SECTION";
export const SET_VALUE_DATE = "SET_VALUE_DATE";
export const RESET_QUESTION = "RESET_QUESTION";
export const VALIDATE_SECTION = "VALIDATE_SECTION";
export const FETCH_SURVEY = "FETCH_SURVEY";
export const RESET_VALUE = "RESET_VALUE";
export const GET_POSTAL_CODE = "GET_POSTAL_CODE";
export const TOGGLE_BT_NEXT = "TOGGLE_BT_NEXT";
export const ADD_PREVIOUS_SECTION = "ADD_PREVIOUS_SECTION";
export const POP_PREVIOUS_SECTION = "POP_PREVIOUS_SECTION";
export const SET_SURVEY = "SET_SURVEY";
export const SET_ID = "SET_ID";
export const SET_CAISSE = "SET_CAISSE";

// toutes les mutations sont appelées ici
const actions = {
  checkError({ commit }, data) {
    commit(CHECK_ERROR, data);
  },
  checkErrorFP({ commit }, data) {
    commit(CHECK_ERROR_FP, data);
  },
  resetValue({ commit }, data) {
    commit(RESET_VALUE, data);
  },
  setValue({ commit }, data) {
    commit(SET_VALUE, data);
  },
  setValueGlobal({ commit }, data) {
    commit(SET_VALUE_GLOBAL, data);
  },
  setValidateSection({ commit }, data) {
    commit(SET_VALIDATE_SECTION, data);
  },
  setValueDate({ commit }, data) {
    commit(SET_VALUE_DATE, data);
  },
  resetQuestion({ commit }, data) {
    commit(RESET_QUESTION, data);
  },
  validateSection({ commit }, data) {
    commit(VALIDATE_SECTION, data);
  },
  checkCompleted({ commit }, data) {
    commit(CHECK_COMPLETED, data);
  },
  fetchSurvey({ commit }, data) {
    // Si code client, on fait un appel a l'api
    if (data && data.codeClient) {
      return axios
        .get(`${process.env.VUE_APP_API_URL}/api/Contacts/${data.codeClient}`)
        .then(ret => {
          commit(FETCH_SURVEY, ret.data);
        })
        .catch(e => console.log(e));
    }
  },
  getAllPostalCode({ commit }) {
    commit(GET_POSTAL_CODE);
  },
  sendSurvey({ commit }, data) {
    let survey = data.survey;
    let responses = {};
    let arrayResponse = [];
    execSurveyDeep(
      survey,
      (s, sn) => {
        if (s.serverPathValidated && get(survey, `sections.${sn}.isValidated`) === true) {
          responses = setFP(s.serverPathValidated, true, responses);
        }
      },
      (q, p, rp, isArray) => {
        const serverPath = getProp(q, "serverPath", rp);
        if (serverPath && q.questionType && getResponseFromSurvey[q.questionType]) {
          const values = getResponseFromSurvey[q.questionType](
            survey,
            getPath(p, rp, "response"),
            serverPath,
            q.resetServerPath,
            split(p, ".").pop(),
            p
          );
          if (isArray) {
            arrayResponse =
              get(responses, serverPath) !== undefined
                ? [...get(responses, serverPath), values]
                : [values];
            responses = setFP(serverPath, arrayResponse, responses);
          } else if (typeof values === "object" && keys(values).length > 0) {
            forEach(values, (value, path) => {
              responses = setFP(path, value, responses);
            });
          } else {
            if (get(responses, serverPath) === undefined) {
              responses = setFP(serverPath, values, responses);
            }
          }
        }
      }
    );
    // créer un nouveau contact
    axios
      .post(`${process.env.VUE_APP_API_URL}/api/Contacts/`, {
        data: responses,
        query: data.query
      })
      .then(({ data }) => {
        commit(SET_ID, data);
      })
      .catch(e => console.log(e));
  },
  toggleBtNext({ commit }, data) {
    commit(TOGGLE_BT_NEXT, data);
  },
  addPreviousSection({ commit }, data) {
    commit(ADD_PREVIOUS_SECTION, data);
  },
  popPreviousSection({ commit }, data) {
    commit(POP_PREVIOUS_SECTION, data);
  },
  setSurvey({ commit }, data) {
    commit(SET_SURVEY, data);
  }
};

export default actions;
