<template>
  <fragment>
    <div
      v-for="(label, value) in getOptions(question.options)"
      :key="value"
      :class="`${question.answerColClass || 'col'} field`"
    >
      <button
        name="question.label"
        type="button"
        :class="['btn btn-primary', question.fieldClass, { selected: isSelected(value) }]"
        @click="onClick(value)"
      >
        <i v-if="question.fieldClass && question.fieldClass.includes('icon')" :class="value" />
        <span>{{ label }}</span>
      </button>

      <!-- Render option precision questions if exists -->
      <question-layout
        v-if="isSelected(value)"
        v-for="(subQuestion, key) in question.optionPrecisions"
        :key="key"
        :questionName="key"
        :question="subQuestion"
        :path="`${path}.optionPrecisions.${key}`"
        :responsePath="getPath(responsePath, `responses.${value}`)"
        :isChildren="true"
      />
    </div>
  </fragment>
</template>
<script>
import { filter } from "lodash";

import { getOptions, getProp, getPath } from "../../utils/SurveyUtils";

export default {
  props: ["question", "path", "responsePath"],
  methods: {
    getOptions,
    getPath,
    isSelected(value) {
      return (getProp(this.question, this.responsePath, "response") || []).includes(value);
    },
    onClick(value) {
      const response = getProp(this.question, this.responsePath, "response") || [];
      return this.$store.dispatch("setValue", {
        path: this.path,
        responsePath: this.responsePath,
        value: response.includes(value) ? filter(response, r => r !== value) : [...response, value],
      });
    },
  },
};
</script>
