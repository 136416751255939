<template>
  <fragment>
    <div class="container text-center">
      <div class="confirmation-block">
        <i class="icon-confirmation check" />
        <div class="confirm-message">
          Vous avez déjà répondu au questionnaire et nous vous en remercions. Vos réponses ont<br />
          bien été prises en compte.
        </div>
        <p></p>
      </div>
      <footer class="confirmation-footer"></footer>
    </div>
  </fragment>
</template>

<style lang="scss" scoped></style>
