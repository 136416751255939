import survey from "../../data/survey.json";

const state = {
  survey,
  showAppHeader: false,
  showAppFooter: false,
  noClientPage: false,
  dejaReponduPage: true,
  surveyWithClientCode: false,
  isSurveyCompleted: false,
  toogleNavbar: false
};

export default state;
